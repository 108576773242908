import { classToClass, plainToClass, Type } from 'class-transformer';
import { RestaurantExplore } from '../domains/restaurant-explore';
import { TypeCategory } from '../domains/type-category';
import { AbbreviatePipe } from '../pipes/abbreviate.pipe';
import { CityPipe } from '../pipes/city.pipe';
import { SearchResultSlideType } from './enums/search-result-slide-type';
import { Picker } from './picker';
import { PickerConfig } from './picker-config';
import { PickerGroup } from './picker-group';
import { SearchResultSlide } from './search-result-slide';
import { TypeCategorySection } from './type-category-section';
import { TypeCategorySectionGroup } from './type-category-section-group';

export class SearchResult {
  @Type(() => TypeCategory)
  typeCategories: TypeCategory[];

  @Type(() => RestaurantExplore)
  exampleRestaurant: RestaurantExplore;

  isReady = false;

  groups: TypeCategorySectionGroup[] = [];
  groupsFiltered: TypeCategorySectionGroup[] = [];

  restaurants: RestaurantExplore[];
  restaurantsLength = 0;

  slides: SearchResultSlide[] = [];

  distanceMin = 0.1;
  distanceMax: number;
  distanceFilterMobile = 0;
  distanceFilterDesktop = 0;

  restaurantsPicker: Picker;

  init() {
    this.setGroups();

    this.filterGroupsAndSetRestaurantsAndSlidesAndPicker();
  }

  setGroups() {
    const combineKeys = [
      [
        ['cafes', 'diner'],
        ['bagels', 'donuts', 'bakery'],
        ['tea', 'dessert'],
      ],
      [
        ['deli', 'sandwich', 'burgers'],
        ['chicken', 'bbq', 'specialty'],
        ['pizza'],
      ],
      [
        ['mexican', 'latin'],
        ['med', 'mid'],
        ['indian', 'international']
      ],
      [
        ['chinese'],
        ['thai', 'vietnamese', 'asian'],
        ['japanese', 'korean']
      ],
      [
        ['italian', 'european'],
        ['american new', 'traditional'],
        ['bar', 'brewery']
      ],
      [
        ['activity']
      ]
    ];

    const typeCategorySections = [];
    const minRelativeDistances = [];

    this.typeCategories.forEach((typeCategory: TypeCategory) => {
      const keys = combineKeys[typeCategory.section - 1];

      keys.forEach(typeCategoryKeys => {
        typeCategoryKeys.forEach(typeCategoryKey => {
          if (typeCategoryKey === typeCategory.key) {
            let typeCategorySection = typeCategorySections.find(typeCategorySectionI =>
              typeCategorySectionI.keys.find((typeCategorySectionId: string) =>
                typeCategorySectionId === typeCategory.key
              )
            );

            if (typeCategorySection === undefined) {
              typeCategorySection = new TypeCategorySection();

              typeCategorySection.keys = typeCategoryKeys;
              typeCategorySection.section = typeCategory.section;

              typeCategorySections.push(typeCategorySection);
            }

            if (!typeCategorySection.hasChain) {
              typeCategorySection.hasChain = typeCategory.hasChain;
            }

            if (!typeCategorySection.hasRestaurants) {
              typeCategorySection.hasRestaurants = typeCategory.restaurants.length > 0;
            }

            typeCategorySection.titles.push(typeCategory.display);
            typeCategorySection.typeCategories.push(typeCategory);
          }
        });
      });
    });

    const typeCategorySectionGroups: TypeCategorySectionGroup[] = [];

    typeCategorySections.forEach((typeCategorySection: TypeCategorySection) => {
      // typeCategorySection.chains.sort((a, b) => a.id > b.id ? 1 : -1);

      let typeCategorySectionGroup = typeCategorySectionGroups.find(searchSection => {
        return searchSection.section === typeCategorySection.section;
      });

      if (typeCategorySectionGroup === undefined) {
        typeCategorySectionGroup = new TypeCategorySectionGroup();
        typeCategorySectionGroup.section = typeCategorySection.section;

        typeCategorySectionGroups.push(typeCategorySectionGroup);
      }

      typeCategorySection.typeCategories.forEach(typeCategory => {
        typeCategory.restaurants.forEach(restaurant => {
          minRelativeDistances.push(restaurant.relativeDistance);

          if (!this.distanceMax || restaurant.relativeDistance > this.distanceMax) {
            this.distanceMax = Math.ceil(restaurant.relativeDistance * 10) / 10;
          }
        });
      });

      typeCategorySectionGroup.sections.push(typeCategorySection);
    });

    const typeCategoryExample = new TypeCategory();
    typeCategoryExample.restaurants.push(this.exampleRestaurant);

    const typeCategorySectionExample = new TypeCategorySection();
    typeCategorySectionExample.isExample = true;
    typeCategorySectionExample.titles = ['M Powered'];
    typeCategorySectionExample.keys = ['example'];
    typeCategorySectionExample.typeCategories.push(typeCategoryExample);
    typeCategorySectionExample.hasRestaurants = true;

    const typeCategorySectionGroupExample = new TypeCategorySectionGroup();
    typeCategorySectionGroupExample.sections.push(typeCategorySectionExample);

    typeCategorySectionGroups.push(typeCategorySectionGroupExample);

    this.distanceFilterMobile = this.distanceMax;

    this.groups = typeCategorySectionGroups;

    minRelativeDistances.sort((a, b) => a > b ? 1 : -1);

    this.distanceMin = Math.floor(minRelativeDistances[0] * 10) / 10;
  }

  filterGroupsAndSetRestaurantsAndSlidesAndPicker(relativeDistance = null) {
    this.groupsFiltered = classToClass(this.groups);

    if (relativeDistance) {
      this.groupsFiltered.forEach(group => {
        group.sections.forEach(section => {
          section.typeCategories.forEach(typeCategory => {
            typeCategory.restaurants = typeCategory.restaurants.filter(restaurant => {
              return restaurant.relativeDistance === 0 || restaurant.relativeDistance < (relativeDistance + 0.1);
            });
          });
        });

        group.sections = group.sections.filter(section => section.typeCategories.length > 0);

        group.sections.forEach(section => {
          section.hasRestaurants =
            section.typeCategories.some(typeCategory => typeCategory.restaurants.length > 0);
        });
      });

      this.groupsFiltered = this.groupsFiltered.filter(group => group.sections.length > 0);
    }

    this.restaurants = [];
    this.restaurantsLength = 0;
    this.slides = [];
    const pickerGroups = [];

    this.groupsFiltered.forEach((typeCategorySectionGroup: TypeCategorySectionGroup) => {
      typeCategorySectionGroup.sections.forEach(typeCategorySectionI => {
        if (typeCategorySectionI.hasChain || typeCategorySectionI.hasRestaurants) {
          const optGroup = new PickerGroup(typeCategorySectionI.title);

          typeCategorySectionI.typeCategories.forEach((typeCategory) => {
            if (typeCategory.hasRestaurants) {
              typeCategory.restaurants.forEach((restaurant) => {
                optGroup.addOption(
                  restaurant.hostname,
                  new CityPipe().transform(
                    new AbbreviatePipe().transform(restaurant.nameView, false, ''),
                    restaurant.cityNameToRemove
                  )
                );
              });
            }
          });

          pickerGroups.push(optGroup);

          const dividerSlide = new SearchResultSlide();

          dividerSlide.type = SearchResultSlideType.DIVIDER;
          dividerSlide.keysString = typeCategorySectionI.keysString;
          dividerSlide.typeCategorySection = typeCategorySectionI;
          this.slides.push(dividerSlide);
        }

        typeCategorySectionI.typeCategories.forEach(typeCategoryI => {
          this.restaurants = [...this.restaurants, ...typeCategoryI.restaurants];

          typeCategoryI.restaurants.forEach(restaurant => {
            const restaurantSlide = new SearchResultSlide();

            restaurantSlide.type = SearchResultSlideType.RESTAURANT;
            restaurantSlide.restaurantId = restaurant.id;
            restaurantSlide.restaurant = restaurant;
            this.slides.push(restaurantSlide);
          });
        });
      });
    });

    this.restaurantsPicker = new Picker(plainToClass(PickerConfig, {groups: pickerGroups}));
    this.restaurantsPicker.defaultGroup.addOption('search', 'SEARCH');

    let previousRestaurant: RestaurantExplore;

    this.restaurants.forEach(restaurantI => {
      if (!restaurantI.isExample) {
        this.restaurantsLength++;
      }

      if (previousRestaurant) {
        previousRestaurant.nextRestaurantId = restaurantI.id;
        restaurantI.previousRestaurantId = previousRestaurant.id;
      }

      previousRestaurant = restaurantI;
    });

    if (this.restaurants.length > 0) {
      const lastRestaurant = this.restaurants[this.restaurants.length - 1];
      lastRestaurant.nextRestaurantId = this.exampleRestaurant.id;
      this.exampleRestaurant.previousRestaurantId = lastRestaurant.id;
    }
  }

  getSlideIndex(slide: SearchResultSlide) {
    const index = this.slides.indexOf(slide);

    if (index === -1) {
      return 0;
    }

    return index;
  }

  getSlideByRestaurant(restaurant: RestaurantExplore): SearchResultSlide {
    return this.slides.find(slideI =>
      slideI.type === SearchResultSlideType.RESTAURANT && slideI.restaurantId === restaurant.id
    ) || null;
  }

  getSlideIndexByRestaurant(restaurant: RestaurantExplore) {
    const slide = this.getSlideByRestaurant(restaurant);

    return this.getSlideIndex(slide);
  }

  getSlideByChains(typeCategorySection: TypeCategorySection): SearchResultSlide {
    return this.slides.find(slideI => {
      return slideI.type === SearchResultSlideType.CHAIN && slideI.keysString === typeCategorySection.keysString;
    });
  }

  getSlideByDivider(typeCategorySection: TypeCategorySection): SearchResultSlide {
    return this.slides.find(slideI => {
      return slideI.type === SearchResultSlideType.DIVIDER &&
        slideI.keysString === typeCategorySection.keysString;
    });
  }

  getSlideIndexByDivider(typeCategorySection: TypeCategorySection) {
    const slide = this.getSlideByDivider(typeCategorySection);

    return this.getSlideIndex(slide);
  }

  getPreviousSlide(activeSearchResultSlide: SearchResultSlide) {
    const index = this.slides.indexOf(activeSearchResultSlide);

    if (index === 0) {
      return null;
    }

    return this.slides[index - 1];
  }

  getNextSlide(activeSearchResultSlide: SearchResultSlide) {
    const index = this.slides.indexOf(activeSearchResultSlide);

    if (index === this.slides.length - 1) {
      return null;
    }

    return this.slides[index + 1];
  }

  setSlideAndNeighborsLoaded(slideIndex: number) {
    const slide = this.slides[slideIndex];

    if (slide && !slide.loaded) {
      slide.loaded = true;
    }

    const previousSlide = this.getPreviousSlide(slide);

    if (previousSlide && !previousSlide.loaded) {
      previousSlide.loaded = true;
    }

    const nextSlide = this.getNextSlide(slide);

    if (nextSlide && !nextSlide.loaded) {
      nextSlide.loaded = true;
    }
  }

  setSlidesLoadedFalse() {
    this.slides.forEach(slide => {
      slide.loaded = false;
    });
  }

  getRestaurantById(restaurantId: number): RestaurantExplore {
    return this.restaurants.find(restaurant => restaurant.id === restaurantId);
  }

  getTypeCategorySectionByRestaurantId(restaurantId: number): TypeCategorySection {
    let typeCategorySection: TypeCategorySection = null;

    this.groupsFiltered.forEach(group => {
      group.sections.forEach(section => {
        section.typeCategories.forEach(typeCategory => {
          typeCategory.restaurants.forEach(restaurant => {
            if (restaurant.id === restaurantId) {
              typeCategorySection = section;
            }
          });
        });
      });
    });

    return typeCategorySection;
  }
}
