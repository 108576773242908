<div
  *ngIf="!isMobile"
  class="form-control-style bg-container"
  (mouseover)="inputHover = true"
  (mouseleave)="inputHover = false"
>
  <ng-container *ngIf="!inputHover">
    <ng-container *ngTemplateOutlet="searchIcon"></ng-container>
  </ng-container>

  <div class="row m-0 p-0 d-flex justify-content-between">
    <div
      [ngClass]="{'bg-container': inputHover}"
      class="search-container"
      (mouseover)="leftInputHover = true"
      (mouseleave)="leftInputHover = false"
    >
      <ng-container *ngIf="!leftInputValue && inputHover">
        <ng-container *ngTemplateOutlet="searchIcon"></ng-container>
      </ng-container>

      <app-search-restaurant-input
        [searchParams]="searchParams"
        [inputHover]="leftInputHover"
        class="w-100"
        (inputClick)="onLeftInputClick($event)"
      ></app-search-restaurant-input>
    </div>

    <div
      [ngClass]="{'bg-container': inputHover}"
      class="search-container"
      (mouseover)="rightInputHover = true"
      (mouseleave)="rightInputHover = false"
    >
      <ng-container *ngIf="!rightInputValue && inputHover">
        <ng-container *ngTemplateOutlet="searchIcon"></ng-container>
      </ng-container>

      <app-search-city-input
        [inputHover]="rightInputHover"
        class="w-100"
        (selectPlace)="onSelectPlace($event)"
        (onFindMeClick)="onFindMe()"
        (inputClick)="onRightInputClick($event)"
      ></app-search-city-input>
    </div>
  </div>
</div>

<ng-template #searchIcon>
  <div class="icon-search-container position-absolute flex-content-vc">
    <i class="icon-v2 search-black-new ml-3 scale-0-7"></i>
  </div>
</ng-template>

<ng-template #searchCityModalRef>
  <app-search-location-modal
    [searchParams]="searchParams"
    (selectPlace)="onSelectPlace($event)"
    (findMe)="onFindMe()"
    (selectRestaurant)="onSelectRestaurant($event)"
  ></app-search-location-modal>
</ng-template>
