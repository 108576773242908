<div *ngIf="restaurantReady" class="d-flex flex-column w-100 h-100 overflow-hidden">
  <div *ngIf="restaurant" class="restaurant-preview-content">
    <button class="d-none"></button>

    <div *ngIf="!isHeroPageOpen" class="restaurant-preview-body d-flex flex-column bg-black position-relative">
      <app-div-rectangle [ratio]="0.8" class="position-relative">
        <div class="restaurant-preview-overlay-logo position-absolute-0 z-index-3 pointer-events-none">
          <div class="w-100 pointer-events-auto ">
            <div class="m-icon position-absolute top-0" (click)="statsClicked()">
              <div *ngIf="!restaurant.isOwned" class="pb-0 d-flex flex-column align-items-center">
                <i class="icon-v2 stats opacity-30 cursor-pointer scale-0-8" ></i>
              </div>

              <div
                *ngIf="!restaurant.isOwned"
                class="font-size-0-8rem z-index-1 p-0 text-white d-flex cursor-pointer text-shadow-70"
              >
                <span class="text-center w-100">{{ restaurant.statRating | shortNumberFormat }}</span>
              </div>
            </div>

            <app-instagram-helper
              [restaurant]="restaurant"
              [igUsernameSuggestion]="igUsernameSuggestion"
            ></app-instagram-helper>
          </div>

          <div
            class="text-white d-flex justify-content-center z-index-1 mt-4 ml-1-75rem mr-1-75rem pointer-events-auto"
            (click)="openGoogleMap()"
          >
            <ng-container *ngIf="!restaurant.details.logo">
              <div class="text-center align-items-end cursor-pointer text-shadow-70">
                <div class="rest-name">
                  {{ restaurant.nameView | abbreviate: false: '' | city: restaurant.cityNameToRemove }}
                </div>

                <div *ngIf="restaurant.secondName" class="mobile-second-name">
                  {{ restaurant.secondNameView }}
                </div>
              </div>
            </ng-container>

            <div class="restaurant-preview-logo align-self-center cursor-pointer">
              <img
                *ngIf="restaurant.details.logo"
                [src]="restaurant.details.logo.path | imgix: {w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
                [title]="restaurant.nameView | abbreviate: false: '' | city: restaurant.cityNameToRemove"
                [alt]="restaurant.nameView | abbreviate: false: '' | city: restaurant.cityNameToRemove"
              />
            </div>
          </div>
        </div>

        <div
          *ngIf="!isHoursEnabled && !restaurant.slideGalleryFoods.empty"
          class="restaurant-preview-overlay-navigation position-absolute-0 z-index-2"
        >
          <div class="d-flex w-100 h-100 z-index-1">
            <div
              class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right-{{restaurant.id}}"
            ></div>

            <div
              class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right-{{restaurant.id}}"
            ></div>
          </div>
        </div>

        <app-preview-modal-slide
          *ngIf="isHoursEnabled"
          [restaurant]="restaurant"
          [backgroundImage]="backgroundImage"
          [noGradient]="true"
        >
          <div
            class="restaurant-preview-hours-content mt-4 pt-4 text-white card-body"
            (click)="goToRestaurant()"
          >
            <app-restaurant-hours
              [isDesktop]="false"
              [restaurantHoursSections]="restaurant.hoursSectionsPreview"
            ></app-restaurant-hours>
          </div>
        </app-preview-modal-slide>

        <app-preview-modal-slide
          *ngIf="!isHoursEnabled && restaurant.slideGalleryFoods.empty"
          [restaurant]="restaurant"
          [backgroundImage]="backgroundImage"
          [noGradient]="true"
          [hoursEnabled]="false"
        ></app-preview-modal-slide>

        <swiper
          *ngIf="!isHoursEnabled && !restaurant.slideGalleryFoods.empty"
          [config]="imagesConfig"
          class="h-100"
          (slideChange)="slideChange($event)"
        >
          <ng-template *ngIf="restaurant.slideGalleryFoods.googleImages.length == 0" swiperSlide>
            <app-preview-modal-slide
              [restaurant]="restaurant"
              [backgroundImage]="
                restaurant.slideGalleryFoods.inside ?
                (restaurant.slideGalleryFoods.inside.images[0].path | imgix) :
                backgroundImage
              "
              [noGradient]="true"
              [hoursEnabled]="isHoursEnabled"
            ></app-preview-modal-slide>
          </ng-template>

          <ng-template swiperSlide *ngFor="let topFood of restaurant.slideGalleryFoods.topFoods">
            <app-preview-modal-slide
              [restaurant]="restaurant"
              [backgroundImage]="backgroundImage"
              [topFood]="topFood"
              [hoursEnabled]="isHoursEnabled"
            ></app-preview-modal-slide>
          </ng-template>

          <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.outside">
            <app-preview-modal-slide
              [restaurant]="restaurant"
              [backgroundImage]="restaurant.slideGalleryFoods.outside.images[0].path | imgix"
              [hoursEnabled]="isHoursEnabled"
            ></app-preview-modal-slide>
          </ng-template>

          <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.inside">
            <app-preview-modal-slide
              [restaurant]="restaurant"
              [backgroundImage]="restaurant.slideGalleryFoods.inside.images[0].path | imgix"
              [hoursEnabled]="isHoursEnabled"
            ></app-preview-modal-slide>
          </ng-template>

          <ng-template swiperSlide *ngFor="let googleImage of restaurant.slideGalleryFoods.googleImages">
            <app-preview-modal-slide
                [restaurant]="restaurant"
                [backgroundImage]="googleImage.images[0].path | googleImage:360"
                [hoursEnabled]="isHoursEnabled"
            ></app-preview-modal-slide>
          </ng-template>
        </swiper>

        <div
          [ngClass]="{'visibility-hidden': !(!isHoursEnabled && !restaurant.slideGalleryFoods.empty)}"
          class="restaurant-preview-overlay-pagination w-100 d-flex justify-content-center"
        >
          <div class="pointer-events-auto">
            <div
              class="restaurant-preview-swiper-pagination restaurant-preview-swiper-pagination-{{restaurant.id}}"
            ></div>
          </div>
        </div>
      </app-div-rectangle>

      <div #scroller id="scroller"></div>

      <div *ngIf="!isHoursEnabled" class="video-list mx-0">
        <div *ngFor="let restaurantEmbed of restaurantEmbeds; let last = last;" [ngClass]="{'mb-3': !last}">
          <app-embed-video
            [restaurant]="restaurant"
            [embed]="restaurantEmbed"
            (deleteEmbed)="onDeleteUserEmbed(restaurantEmbed)"
          ></app-embed-video>
        </div>
      </div>

      <div *ngIf="!isHoursEnabled" class="d-flex flex-column align-items-center bg-dark-on-modal text-white">
        <div
          class="d-flex justify-content-center p-1-3rem photo-booth video-font opacity-50"
          (click)="openVideoModal()"
        >
          Link video
        </div>
      </div>

      <app-div-rectangle *ngIf="isHoursEnabled">
        <div class="d-flex flex-column align-items-center bg-dark-on-modal">
          <div class="m-auto"></div>

          <span
            class="icon chains-circle-dark scale-5-5 cursor-pointer"
            (click)="photoBooth()"
          ></span>

          <div class="m-auto"></div>

          <div
            class="d-flex justify-content-center mb-0-35rem text-white photo-booth"
            (click)="photoBooth()"
          >
            Photo Booth
          </div>
        </div>
      </app-div-rectangle>
    </div>

    <div *ngIf="isHeroPageOpen" class="restaurant-preview-body d-flex flex-column safe-area-padding-top bg-white">
      <app-navbar-top [title]="restaurant.nameView"></app-navbar-top>

      <app-div-rectangle class="d-flex flex-column mt-hero">
        <div class="text-center header w-100 h-100">
          <div class="overflow-hidden w-100 h-100">
            <div class="bg-gradient w-100 h-100 text-white">
              <div class="text-center">
                <div class="font-size-1rem p-3 pt-5">
                  Organize this photo journal <br> into a M menu
                </div>
              </div>

              <div class="d-flex flex-column">
                <div class="explore-icon flex-content-hc">
                  <div class="align-self-center">
                    <div class="icon-v2 hero"></div>
                  </div>
                </div>
              </div>

              <div class="shadow-block mt-2"></div>
            </div>
          </div>
        </div>
      </app-div-rectangle>

      <div class="m-auto"></div>

      <div class="mb-2 mx-3">
        <button
          appClickStopPropagation
          type="button"
          class="btn btn-block btn-white"
          (click)="goToRestaurantToEditMode()"
        >
          Fill out this menu
        </button>
      </div>
    </div>
  </div>

  <div>
    <app-preview-modal-footer
      [restaurant]="restaurant"
      [searchParams]="searchParams"
      [isHoursEnabled]="isHoursEnabled"
      (goToRestaurantPage)="goToRestaurant()"
      (hoursToggle)="setIsHoursEnabled($event)"
      (isHeroModalOpen)="isHeroModalOpen($event)"
      (videoAdded)="onVideoAdded($event)"
    ></app-preview-modal-footer>
  </div>
</div>

<ng-template #videoModalRef let-c="close">
  <app-video-modal
    [searchParams]="searchParams"
    [restaurant]="restaurant"
    [type]="videoModalType.REGULAR"
    (videoAdded)="onVideoAdded($event)"
  ></app-video-modal>
</ng-template>

<ng-template #instagramInputModalRef let-c="close">
  <app-instagram-input-modal
    [restaurant]="restaurant"
    [igUsernameSuggestion]="igUsernameSuggestion"
    (igUsernameSuggested)="onIgUsernameSuggested($event)"
  ></app-instagram-input-modal>
</ng-template>

<div *ngIf="!restaurantReady" class="d-flex flex-column w-100 h-100">
  <div class="preview-modal-skeleton"></div>
</div>
