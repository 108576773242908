<nav
  [ngClass]="{'navbar-top-loader': findMeLoader}"
  class="navbar navbar-top navbar-top-px-0 bg-white fixed-top max-width safe-area-top"
  (click)="onNavbarClick()"
>
  <div *ngIf="findMeLoader" class="w-100 h-100">
    <ngx-skeleton-loader
      count="1"
      animation="progress-dark"
      [theme]="{
        'width': '100%',
        'height': '44px',
        'background-color': '#ffffff20'
      }"
    ></ngx-skeleton-loader>
  </div>

  <ng-container *ngIf="!findMeLoader">
    <div class="navbar-top-left">
      <div
        *ngIf="searchParams.geoLocation"
        class="pl-2 icon-v2 close-circle flex-content-vc scale-0-7"
        (click)="clearGeoLocation($event)"
      >
      </div>
    </div>

    <div class="text-black explore-title">{{ searchParams.locationName }}</div>

    <div class="navbar-top-right">
      <app-distance-filter
        *ngIf="searchParams.geoLocation"
        [position]="distanceSliderPosition.NAV_BAR"
        [searchParams]="searchParams"
        [searchResult]="searchResult"
        class="pr-2"
      ></app-distance-filter>
    </div>
  </ng-container>
</nav>
