<div
  *ngIf="!isUserPage"
  class="user-sidenav h-100"
  (mouseover)="sidebarHover = true"
  (mouseleave)="sidebarHover = false"
>
  <div class="w-100 h-100 flex-content-hr">
    <div class="user-sidenav-menu text-white flex-content-hc flex-column opacity-07-to-1-on-hover">
      <div
        *ngIf="!isSearchModalOpen && !isRestaurantPage"
        class="flex-content-hc mb-2rem"
        (mouseover)="setSidenavHover(true)"
        (mouseleave)="setSidenavHover(false)"
      >
        <a
          appHrefNone
          [ngClass]="{
            'icon-v2 chains-circle-white': admin || sidebarHover,
            'icon chains-circle': !sidebarHover && !admin
          }"
          class="cursor-pointer"
          (click)="openMbershipModal()"
        ></a>
      </div>

      <div *ngIf="isLoggedIn && !isSearchModalOpen && !isUserPage && !isRestaurantPage" class="flex-content-hc mb-2rem">
        <a class="icon-v2 profile-white cursor-pointer" (click)="goToUser()"></a>
      </div>

      <div *ngIf="!isSearchModalOpen && !isUserPage && !isRestaurantPage && !admin" class="flex-content-hc mb-2rem">
        <a
          [ngClass]="{'profile-full': sidenavHover || sidebarHover,'profile-dark': !sidebarHover}"
          class="icon-v2 cursor-auto"
        ></a>
      </div>

      <div *ngIf="isExplorePage && !isSearchModalOpen" class="flex-content-hc mb-2rem">
        <a class="icon-v2 explore-full cursor-auto"></a>
      </div>

      <div *ngIf="!isExplorePage && !isUserPage" class="flex-content-hc mb-2rem">
        <a
          class="icon-v2 clock cursor-pointer"
          (click)="scrollTop()"
        ></a>
      </div>

      <div *ngIf="!isExplorePage && !isUserPage" class="flex-content-hc mb-2rem">
        <a
          routerLink="/"
          [ngClass]="{'explore': !sidenavHover && !sidebarHover,'explore-white': !isUserPage || sidebarHover}"
          class="icon-v2 cursor-pointer"
        ></a>
      </div>

      <div *ngIf="!isExplorePage && !isUserPage && !currentRestaurant.isOwned" class="flex-content-hc mb-2rem">
        <a
          class="icon-v2 location-new-white cursor-pointer"
          (click)="goToMap()"
        ></a>
      </div>

      <div class="mt-auto"></div>
    </div>
  </div>
</div>

<ng-template #restaurantModalRef>
  <app-restaurant-modal [restaurant]="currentRestaurant" class="h-100"></app-restaurant-modal>
</ng-template>

<ng-template #videoModalRef>
  <app-video-desktop-modal></app-video-desktop-modal>
</ng-template>
