<div
  [appBackgroundUrl]="
    topFood && topFood.food.images.length > 0 ? (topFood.food.images[0]?.path | imgix) : backgroundImage
  "
  class="restaurant-preview-slide d-flex card flex-column h-100 mt-0 border-radius-none bg-black"
  (mouseover)="pictureHover = true"
  (mouseleave)="pictureHover = false"
>
  <div
    [ngClass]="{
      'preview-modal-slide-gradient-desktop': !noGradient && isDesktop,
      'preview-modal-backdrop': noGradient && hoursEnabled,
      'preview-modal-slide-gradient': !isDesktop && !hoursEnabled
    }"
    class="position-absolute-0 d-flex flex-column cursor-pointer"
    (click)="goToFood(topFood?.food)"
  >
    <ng-content></ng-content>

    <div
      *ngIf="!noFooter && !isDesktop"
      class="street-line mt-auto blue-shadow"
    >
      <div *ngIf="!topFood || topFood.food?.gallery" class="row" (click)="openGoogleMap()">
        <div class="col-12 text-center text-white text-shadow-70 mb-0-35rem">
          <ng-container *ngIf="restaurant.city.airport">
            {{ restaurant.city.display }}
          </ng-container>

          <ng-container *ngIf="!restaurant.city.airport">
            {{ restaurant.addressStreet }}
            <span *ngIf="!restaurant.isOwned" class="font-size-0-98rem">•</span>
            <span *ngIf="!restaurant.isOwned">{{ restaurant.cityAndState }}</span>
          </ng-container>
        </div>
      </div>

      <div *ngIf="topFood && !topFood.food?.gallery" (click)="goToFood(topFood.food)">
        <div class="text-white mb-0-35rem w-100 d-flex justify-content-center">
          <div class="d-flex cursor-pointer text-shadow-70">
            <div class="text-shadow">
              <span>{{ topFood.food.title }}</span>
            </div>

            <ng-container *ngIf="topFood.favoriteCount > 0">
              <div class="street-line-heart flex-content-vc-hc">
                <span class="icon-v2 heart-white scale-055 opacity-65"></span>
              </div>

              <div class="text-shadow">
                <span>{{ topFood.favoriteCount }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
