<div class="search-desktop-main pl-2rem pb-2rem flex-fill">
  <app-search-desktop-topnav-loader></app-search-desktop-topnav-loader>

  <div class="search-desktop-category-section pt-1-8rem search-desktop-category-section-first">
    <div class="search-desktop-category">
      <div class="flex-content-vc-hr">
        <div class="search-desktop-restaurant-header d-flex">
          <div class="flex-fill">
            <ngx-skeleton-loader
              count="10"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '30px',
                'width': '350px',
                'border-radius': '0',
                'margin-bottom': '2.5rem',
                'margin-left': '16px',
                'background-color': '#ffffff20'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="search-desktop-sidebar overflow-auto bottom-0 mt-4-5rem">
    <div class="search-desktop-controls d-block">
      <div class="search-desktop-controls-content d-flex">
        <div class="search-desktop-restaurants">
          <div class="mt-1">
            <ngx-skeleton-loader
              count="3"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '18px',
                'width': '160px',
                'border-radius': '0.5rem',
                'background-color': '#ffffff20',
                'margin-bottom': '1.1rem',
                'margin-left': '1rem'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="search-desktop-section search-desktop-section-margin">
            <ngx-skeleton-loader
              count="3"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '18px',
                'width': '160px',
                'border-radius': '0.5rem',
                'background-color': '#ffffff20',
                'margin-bottom': '1.1rem',
                'margin-left': '1rem'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="search-desktop-section search-desktop-section-margin">
            <ngx-skeleton-loader
              count="3"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '18px',
                'width': '160px',
                'border-radius': '0.5rem',
                'background-color': '#ffffff20',
                'margin-bottom': '1.1rem',
                'margin-left': '1rem'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="search-desktop-section search-desktop-section-margin">
            <ngx-skeleton-loader
              count="3"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '18px',
                'width': '160px',
                'border-radius': '0.5rem',
                'background-color': '#ffffff20',
                'margin-bottom': '1.1rem',
                'margin-left': '1rem'
              }"
            ></ngx-skeleton-loader>
          </div>

          <div class="search-desktop-section search-desktop-section-margin">
            <ngx-skeleton-loader
              count="3"
              animation="progress-dark"
              [theme]="{
                'display': 'block',
                'height': '18px',
                'width': '160px',
                'border-radius': '0.5rem',
                'background-color': '#ffffff20',
                'margin-bottom': '1.1rem',
                'margin-left': '1rem'
              }"
            ></ngx-skeleton-loader>
          </div>
        </div>

        <div class="search-desktop-filters d-flex flex-column"></div>
      </div>
    </div>
  </div>
</div>



