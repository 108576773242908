import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { DeviceType } from '../../../../models/enums/device-type';
import { UnsplashImage } from '../../../../models/unsplash-image';

@Component({
  selector: 'app-restaurant-slide-gallery',
  templateUrl: './restaurant-slide-gallery.component.html',
  styleUrls: ['./restaurant-slide-gallery.component.scss'],
})
export class RestaurantSlideGalleryComponent implements OnInit {
  @Input() restaurant: RestaurantSimple | Restaurant;
  @Input() backgroundImage: UnsplashImage | string;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  imagesConfig: SwiperOptions;
  sliderIsHovered = false;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.isMobile) {
      this.imagesConfig = {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          clickable: true,
          el: '.restaurant-preview-swiper-pagination-' + this.restaurant.id,
          renderBullet: (index, className) => {
            return `<span class="swiper-pagination-bullet"></span>`;
          },
        },
        autoplay: {
          delay: 30000,
          disableOnInteraction: false
        },
        noSwiping: true,
        allowTouchMove: false,
        navigation: {
          enabled: true,
          prevEl: '.restaurant-preview-swiper-navigation-left-' + this.restaurant.id,
          nextEl: '.restaurant-preview-swiper-navigation-right-' + this.restaurant.id,
        },

        loop: true,
        effect: 'fade'
      };
    }
  }
}
