<div
  *ngIf="!restaurant.slideGalleryFoods.empty"
  class="restaurant-preview-overlay-navigation position-absolute-0 z-index-2"
>
  <div class="d-flex w-100 h-100 z-index-1">
    <div
      class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right-{{restaurant.id}}"
    ></div>

    <div
      class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right-{{restaurant.id}}"
    ></div>
  </div>
</div>

<app-preview-modal-slide
  [restaurant]="restaurant"
  [backgroundImage]="backgroundImage"
  [noGradient]="true"
></app-preview-modal-slide>

<app-preview-modal-slide
  *ngIf="restaurant.slideGalleryFoods.empty"
  [restaurant]="restaurant"
  [backgroundImage]="backgroundImage"
  [noGradient]="true"
  [hoursEnabled]="false"
></app-preview-modal-slide>

<swiper
  *ngIf="!restaurant.slideGalleryFoods.empty"
  [config]="imagesConfig"
  class="h-100"
>
  <ng-template *ngIf="restaurant.slideGalleryFoods.googleImages.length == 0" swiperSlide>
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="
        restaurant.slideGalleryFoods.inside ?
        (restaurant.slideGalleryFoods.inside.images[0].path | imgix) :
        backgroundImage
      "
      [noGradient]="true"
    ></app-preview-modal-slide>
  </ng-template>

  <ng-template swiperSlide *ngFor="let topFood of restaurant.slideGalleryFoods.topFoods">
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="backgroundImage"
      [topFood]="topFood"
    ></app-preview-modal-slide>
  </ng-template>

  <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.outside">
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="restaurant.slideGalleryFoods.outside.images[0].path | imgix"
    ></app-preview-modal-slide>
  </ng-template>

  <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.inside">
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="restaurant.slideGalleryFoods.inside.images[0].path | imgix"
    ></app-preview-modal-slide>
  </ng-template>

  <ng-template swiperSlide *ngFor="let googleImage of restaurant.slideGalleryFoods.googleImages">
    <app-preview-modal-slide
      [restaurant]="restaurant"
      [backgroundImage]="googleImage.images[0].path | googleImage:360"
    ></app-preview-modal-slide>
  </ng-template>
</swiper>
