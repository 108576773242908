<app-desktop-topnav>
  <div class="d-flex justify-content-between w-100">
    <div class="search-desktop-topnav-left flex-content-vc">
      <a
        appHrefNone
        class="icon-v2 mmmm-red scale-09 mx-3"
      ></a>
    </div>

    <div class="search-desktop-topnav-right flex-content-vc">
      <div class="flex-content-vc-hr flex-fill">
        <a
          routerLink="/"
          class="icon-v2 explore cursor-pointer ml-2rem"
        ></a>

        <a
          class="icon chains-circle cursor-pointer ml-2rem"
          (click)="openMembershipModal()"
        ></a>

        <a class="icon-v2 profile-dark-new cursor-pointer ml-2rem mr-2rem"></a>
      </div>
    </div>
  </div>
</app-desktop-topnav>
