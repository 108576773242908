import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentRestaurant } from '../../../decorators/current-restaurant.decorator';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { IsModalOpen } from '../../../decorators/is-modal-open.decorator';
import { IsSidenavMouseOver } from '../../../decorators/is-sidenav-mouse-over.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { Restaurant } from '../../../domains/restaurant';
import { ModalType } from '../../../models/enums/modal-type';
import { SidenavType } from '../../../models/enums/sidenav-type';
import { FavoritesService } from '../../../services/favorites.service';
import { LayoutSidenavService } from '../../../services/layout-sidenav.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RouterService } from '../../../services/router.service';

@Component({
  selector: 'app-layout-sidenav-right',
  templateUrl: './layout-sidenav-right.component.html',
  styleUrls: ['./layout-sidenav-right.component.scss']
})
export class LayoutSidenavRightComponent implements OnInit {
  @Input() admin = false;

  @IsModalOpen(ModalType.SEARCH_SQUARE) isSearchModalOpen: boolean;
  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  @CurrentRestaurant() currentRestaurant: Restaurant;

  @ViewChild('restaurantModalRef') restaurantModalRef: ElementRef;
  @ViewChild('videoModalRef') videoModalRef: ElementRef;

  @IsLoggedIn() isLoggedIn: boolean;

  isExplorePage: boolean;
  isUserPage: boolean;
  isRestaurantPage: boolean;
  sidenavHover: boolean;
  sidebarHover: boolean;

  constructor(
    private layoutSidenavService: LayoutSidenavService,
    private routerService: RouterService,
    private favoritesService: FavoritesService,
    private navigatorService: NavigatorService,
  ) {
    routerService.exploreRouteParamsSubject.subscribe((params) => {
      this.isExplorePage = !!params;
    });

    routerService.userRouteParamsSubject.subscribe((params) => {
      this.isUserPage = !!params;
    });

    routerService.restaurantRouteParamsSubject.subscribe((params) => {
      this.isRestaurantPage = !!params;
    });
  }

  ngOnInit(): void {
  }

  setSidenavHover(sidenavHover: boolean) {
    this.sidenavHover = sidenavHover;
    this.layoutSidenavService.sidenavIsMouseOverSubjects[SidenavType.RIGHT].next(sidenavHover);
  }

  @Auth()
  @Subscribe()
  openMbershipModal() {
  }

  @Auth()
  goToUser() {
    this.navigatorService.goToUser();
  }

  goToMap() {
    this.navigatorService.goToMapsRestaurant(this.currentRestaurant);
  }

  @Auth()
  onFavoriteClick() {
    this.favoritesService.favoriteClickSubject.next(true);
  }

  scrollTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
