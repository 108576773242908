<ng-container *ngIf="isRouteReady">
  <ng-container *appIfIsMobile>
    <app-search-mobile
      *ngIf="searchParams && !currentRestaurant"
      [isSearching]="isSearching"
      [country]="country"
      [searchParams]="searchParams"
      [searchResult]="searchResult"
      (editRestaurantMenus)="onEditRestaurantsMenu($event)"
    ></app-search-mobile>

    <div *ngIf="currentRestaurant" class="has-navbar-both">
      <app-food-menus-cs
        [currentRestaurant]="currentRestaurant"
        (savedChanges)="onSaveChanges()"
      ></app-food-menus-cs>
    </div>
  </ng-container>

  <ng-container *appIfIsDesktop>
    <app-search-desktop
      [country]="country"
      [searchParams]="searchParams"
      [searchResult]="searchResult"
      [isSearching]="isSearching"
      class="w-100 h-100vh d-block"
    ></app-search-desktop>
  </ng-container>

  <ng-template #emailModalRef let-c="close" let-d="dismiss">
    <app-email-modal></app-email-modal>
  </ng-template>
</ng-container>
