<div class="restaurant-modal-body p-0 h-100 w-100">
  <div class="h-100 w-100 bg-black d-flex flex-column overflow-hidden">
    <div class="d-flex flex-column vh-100">
      <div class="w-100 text-white h-100px d-flex align-items-center justify-content-end">
        <div
          style="margin-right: 48px"
          [ngClass]="{
            'restaurant-desktop-header-no-logo': !restaurant?.details.logo,
            'restaurant-desktop-header-logo': restaurant?.details.logo
          }"
        >
          <ng-container *ngIf="restaurant.details.logo">
            <img
              appBlurImg
              [path]="restaurant.details.logo.path"
              [options]="{w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
              [title]="restaurant.nameView"
              [alt]="restaurant.nameView"
              class="logo-img"
            />
          </ng-container>

          <ng-container *ngIf="!restaurant.details.logo">
            <div class="header-container my-3">
              <div class="stats-container d-flex flex-column align-items-center">
                <i
                  class="icon-v2 stats opacity-30 cursor-pointer"
                  (click)="statsClicked()"
                ></i>

                <span class="text-white font-size-0-8rem text-center">
                  {{ restaurant?.statRating | shortNumberFormat }}
                </span>
              </div>

              <div class="restaurant-name-container">
                <div class="restaurant-desktop-header-name text-shadow-70">
                  {{
                    restaurant.nameView |
                      abbreviate:false:'' |
                      city: restaurant.cityNameToRemove
                  }}
                </div>
                <span *ngIf="restaurant.secondName" class="font-size-1-1rem text-shadow-70">
                  {{ restaurant.secondNameView }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="d-flex flex-grow-1">
        <div
          class="text-white d-flex flex-column "
          style="width: calc(50% - 24px); max-height: calc(100vh - 200px); overflow: auto;"
          (click)="owner = false"
        >
          <div class="restaurant-hours-desktop-content">
            <div *ngIf="restaurant?.periodsLength" class="text-white rest-hours-padding">
              <app-restaurant-hours
                [restaurantHoursSections]="restaurant.hoursSectionsView"
                [isDesktop]="true"
                [isModal]="true"
              ></app-restaurant-hours>
            </div>

            <div #modalContent>
              <ng-container *ngIf="needToShowVideo">
                <div class="bottom-section d-flex flex-column mx-2rem pt-3">
                  <div class="input-group">
                    <input
                      #videoInput
                      type="url"
                      class="form-control border-white box-shadow-none text-white"
                      placeholder="Paste a video link featuring {{ restaurant?.nameView | abbreviate: false: '' }}"
                      (input)="validateUrl($event)"
                    />

                    <span *ngIf="inputUrlValid" class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary waves-effect text-transform-none"
                      (click)="onVideoSubmitClick(videoInput.value)"
                    >
                      Submit
                    </button>
                  </span>
                  </div>

                  <div class="text-right">
                  <span class="float-left mt-2 text-white">
                    YouTube • TikTok • Rumble • etc
                  </span>

                    <button
                      type="button"
                      class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"
                      (click)="restoreMenu()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <div
                  *ngIf="needToShowInstagram"
                  class="bottom-section d-flex flex-column mx-2rem"
                  [ngClass]="{'pt-3': !mmmmVideoMenu, 'pt-5-5rem': mmmmVideoMenu}"
                >
                  <div class="input-group">
                    <input
                      #instagramInput
                      type="text"
                      required
                      minlength="3"
                      maxlength="30"
                      placeholder="Add Instagram username"
                      class="form-control border-white box-shadow-none text-white"
                      (input)="validateTextInput($event)"
                    />

                    <span *ngIf="inputTextValid" class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-primary waves-effect text-transform-none ig-submit-btn"
                      (click)="onInstagramSubmitClick(instagramInput.value)"
                    >
                      Submit
                    </button>
                  </span>
                  </div>

                  <div class="text-right">
                  <span class="float-left mt-2 text-white">
                    For {{ restaurant.secondNameView }}
                  </span>

                    <button
                      *ngIf="!mmmmVideoMenu"
                      type="button"
                      class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"
                      (click)="restoreMenu()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <div
            *ngIf="!needToShowInstagram && !needToShowVideo && restaurantEmbeds.length > 0"
            id="myVideo"
            class="text-center bg-black mt-2rem mb-6-5rem"
          >
            <ngx-simplebar #simpleBar class="w-100 bg-dark">
              <div *ngFor="let restaurantEmbed of restaurantEmbeds; let last = last;" [ngClass]="{'mb-3': !last}">
                <app-embed-video
                  *ngIf="restaurant"
                  [restaurant]="restaurant"
                  [embed]="restaurantEmbed"
                  (deleteEmbed)="onDeleteUserEmbed(restaurantEmbed)"
                >
                </app-embed-video>
              </div>
            </ngx-simplebar>
          </div>
        </div>

        <div
          class="bg-secondary text-white d-flex align-items-center justify-content-center position-relative"
          style="width: calc(50% - 24px)"
        >
          <ng-container *ngIf="restaurant">
            <div
              *ngIf="!restaurant.slideGalleryFoods.empty"
              class="restaurant-preview-overlay-navigation position-absolute-0 z-index-10"
            >
              <div class="d-flex w-100 h-100 z-index-4">
                <div
                  class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-left-{{ restaurant.id }}"
                ></div>

                <div
                  class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right-{{ restaurant.id }}"
                ></div>
              </div>
            </div>

            <swiper
              *ngIf="!restaurant.slideGalleryFoods.empty"
              [config]="backgroundImagesConfig"
              class="h-100 w-100"
            >
              <!-- Slides -->
              <ng-template swiperSlide *ngFor="let topFood of restaurant.slideGalleryFoods.topFoods">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="backgroundImage"
                  [topFood]="topFood"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>

              <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.outside">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="restaurant.slideGalleryFoods.outside.images[0].path | imgix"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>

              <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.inside">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="restaurant.slideGalleryFoods.inside.images[0].path | imgix"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>

              <ng-template swiperSlide *ngFor="let googleImage of restaurant.slideGalleryFoods.googleImages">
                <app-preview-modal-slide
                  [restaurant]="restaurant"
                  [backgroundImage]="googleImage.images[0].path | googleImage:360"
                  [hoursEnabled]="true"
                ></app-preview-modal-slide>
              </ng-template>
            </swiper>

            <div
              [ngClass]="{ 'visibility-hidden': restaurant.slideGalleryFoods.empty }"
              class="restaurant-preview-overlay-pagination w-100 d-flex justify-content-center align-items-end"
            >
              <div class="pointer-events-auto">
                <div
                  class="restaurant-preview-swiper-pagination restaurant-preview-swiper-pagination-{{ restaurant.id }}"
                ></div>
              </div>
            </div>
          </ng-container>

          <app-desktop-modal-sidenav
            *ngIf="restaurant"
            [restaurant]="restaurant"
            [basket]="basket"
            [showOwnershipDetails]="owner"
            [basketChange]="basketClick"
            (instagramClicked)="onInstagramLinkClick()"
            (mmmmClicked)="onMmmmClick()"
            (videoMenuClicked)="onVideoMenuClick()"
            (instagramMenuClicked)="onInstagramMenuClick()"
            (ownerClick)="owner = $event"
            (basketChangeClick)="basketClick = $event"
            (sidenavHover)="sidenavHovered($event)"
            (click)="nextSlide()"
            (previousClick)="changeRestaurantToPrevious($event)"
            (nextClick)="changeRestaurantToNext($event)"
            class="z-index-10"
          ></app-desktop-modal-sidenav>
        </div>
      </div>

      <!-- 3. Row split into two columns with fixed height -->
      <div class="d-flex" style="height: 50px;">
        <div class="flex-fill text-white d-flex align-items-center justify-content-center">
          <div class="text-white font-size-0-98rem col-6 flex-content-hc mt-0-7rem cursor-pointer" (click)="onMMMMIconClick()">
            Link video
          </div>
        </div>

        <div class="flex-fill text-dark d-flex align-items-center justify-content-end" style="margin-right: 48px">
          <app-info-bar
            *ngIf="restaurant"
            [isModal]="true"
            [isRestaurantModal]="true"
            [typeCategorySection]=typeCategorySection
            [restaurant]="restaurant"
            class="font-size-0-98rem"
          ></app-info-bar>
        </div>
      </div>
    </div>


<!--    <div *ngIf="isBasketReady" class="d-flex flex-column h-100 w-100 position-relative">-->
<!--      <div class="w-100 position-relative h-100 bg-black">-->
<!--        <div class="text-white top-div-position">-->
<!--          <div-->
<!--            [ngClass]="{-->
<!--              'restaurant-desktop-header-no-logo': !restaurant?.details.logo,-->
<!--              'restaurant-desktop-header-logo': restaurant?.details.logo-->
<!--            }"-->
<!--          >-->
<!--              <ng-container *ngIf="restaurant.details.logo">-->
<!--                <img-->
<!--                  appBlurImg-->
<!--                  [path]="restaurant.details.logo.path"-->
<!--                  [options]="{w: restaurant.details.logo.width, h: restaurant.details.logo.height}"-->
<!--                  [title]="restaurant.nameView"-->
<!--                  [alt]="restaurant.nameView"-->
<!--                  class="logo-img"-->
<!--                />-->
<!--              </ng-container>-->

<!--              <ng-container *ngIf="!restaurant.details.logo">-->
<!--                <div class="header-container d-flex align-items-center justify-content-end">-->
<!--                  <div class="stats-container mt-5 d-flex flex-column align-items-center">-->
<!--                    <i-->
<!--                      class="icon-v2 stats opacity-30 cursor-pointer"-->
<!--                      (click)="statsClicked()"-->
<!--                    ></i>-->

<!--                    <span class="text-white font-size-0-8rem text-center">-->
<!--                      {{ restaurant?.statRating | shortNumberFormat }}-->
<!--                    </span>-->
<!--                  </div>-->

<!--                  <div class="restaurant-name-container">-->
<!--                    <div class="restaurant-desktop-header-name text-shadow-70">-->
<!--                      {{-->
<!--                        restaurant.nameView |-->
<!--                          abbreviate:false:'' |-->
<!--                          city: restaurant.cityNameToRemove-->
<!--                      }}-->
<!--                    </div>-->
<!--                    <span *ngIf="restaurant.secondName" class="font-size-1-1rem text-shadow-70">-->
<!--                      {{ restaurant.secondNameView }}-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="w-100 h-100 d-flex">-->
<!--          <div class="left-content-container h-100 position-relative" (click)="owner = false">-->
<!--            <div class="restaurant-hours-desktop-content">-->
<!--              <div *ngIf="restaurant?.periodsLength" class="position-relative text-white rest-hours-padding">-->
<!--                <app-restaurant-hours-->
<!--                  [restaurantHoursSections]="restaurant.hoursSectionsView"-->
<!--                  [isDesktop]="true"-->
<!--                  [isModal]="true"-->
<!--                ></app-restaurant-hours>-->
<!--              </div>-->

<!--              <div #modalContent>-->
<!--                <ng-container *ngIf="needToShowVideo">-->
<!--                  <div class="bottom-section d-flex flex-column mx-2rem pt-3">-->
<!--                    <div class="input-group">-->
<!--                      <input-->
<!--                        #videoInput-->
<!--                        type="url"-->
<!--                        class="form-control border-white box-shadow-none text-white"-->
<!--                        placeholder="Paste a video link featuring {{ restaurant?.nameView | abbreviate: false: '' }}"-->
<!--                        (input)="validateUrl($event)"-->
<!--                      />-->

<!--                      <span *ngIf="inputUrlValid" class="input-group-append">-->
<!--                      <button-->
<!--                        type="button"-->
<!--                        class="btn btn-primary waves-effect text-transform-none"-->
<!--                        (click)="onVideoSubmitClick(videoInput.value)"-->
<!--                      >-->
<!--                        Submit-->
<!--                      </button>-->
<!--                    </span>-->
<!--                    </div>-->

<!--                    <div class="text-right">-->
<!--                    <span class="float-left mt-2 text-white">-->
<!--                      YouTube • TikTok • Rumble • etc-->
<!--                    </span>-->

<!--                      <button-->
<!--                        type="button"-->
<!--                        class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"-->
<!--                        (click)="restoreMenu()"-->
<!--                      >-->
<!--                        Cancel-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div-->
<!--                    *ngIf="needToShowInstagram"-->
<!--                    class="bottom-section d-flex flex-column mx-2rem"-->
<!--                    [ngClass]="{'pt-3': !mmmmVideoMenu, 'pt-5-5rem': mmmmVideoMenu}"-->
<!--                  >-->
<!--                    <div class="input-group">-->
<!--                      <input-->
<!--                        #instagramInput-->
<!--                        type="text"-->
<!--                        required-->
<!--                        minlength="3"-->
<!--                        maxlength="30"-->
<!--                        placeholder="Add Instagram username"-->
<!--                        class="form-control border-white box-shadow-none text-white"-->
<!--                        (input)="validateTextInput($event)"-->
<!--                      />-->

<!--                      <span *ngIf="inputTextValid" class="input-group-append">-->
<!--                      <button-->
<!--                        type="button"-->
<!--                        class="btn btn-primary waves-effect text-transform-none ig-submit-btn"-->
<!--                        (click)="onInstagramSubmitClick(instagramInput.value)"-->
<!--                      >-->
<!--                        Submit-->
<!--                      </button>-->
<!--                    </span>-->
<!--                    </div>-->

<!--                    <div class="text-right">-->
<!--                    <span class="float-left mt-2 text-white">-->
<!--                      For {{ restaurant.secondNameView }}-->
<!--                    </span>-->

<!--                      <button-->
<!--                        *ngIf="!mmmmVideoMenu"-->
<!--                        type="button"-->
<!--                        class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"-->
<!--                        (click)="restoreMenu()"-->
<!--                      >-->
<!--                        Cancel-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </ng-container>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div-->
<!--              *ngIf="!needToShowInstagram && !needToShowVideo && restaurantEmbeds.length > 0"-->
<!--              id="myVideo"-->
<!--              class="text-center bg-black mt-2rem mb-6-5rem"-->
<!--            >-->
<!--              <ngx-simplebar #simpleBar class="w-100 bg-dark">-->
<!--                <div *ngFor="let restaurantEmbed of restaurantEmbeds; let last = last;" [ngClass]="{'mb-3': !last}">-->
<!--                  <app-embed-video-->
<!--                    *ngIf="restaurant"-->
<!--                    [restaurant]="restaurant"-->
<!--                    [embed]="restaurantEmbed"-->
<!--                    (deleteEmbed)="onDeleteUserEmbed(restaurantEmbed)"-->
<!--                  >-->
<!--                  </app-embed-video>-->
<!--                </div>-->
<!--              </ngx-simplebar>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div-->
<!--            *ngIf="(basket?.isEmpty() && !owner) || !basketClick"-->
<!--            class="restaurant-modal-right bg-black content-container h-100"-->
<!--          >-->
<!--            <div-->
<!--              *ngIf="!restaurant.slideGalleryFoods.empty"-->
<!--              class="restaurant-preview-overlay-navigation position-absolute-0 z-index-10"-->
<!--            >-->
<!--              <div class="d-flex w-100 h-100 z-index-4">-->
<!--                <div-->
<!--                  class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-left-{{ restaurant.id }}"-->
<!--                ></div>-->

<!--                <div-->
<!--                  class="restaurant-preview-swiper-navigation restaurant-preview-swiper-navigation-right-{{ restaurant.id }}"-->
<!--                ></div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <swiper-->
<!--              *ngIf="!restaurant.slideGalleryFoods.empty"-->
<!--              [config]="backgroundImagesConfig"-->
<!--              class="h-100 w-100"-->
<!--            >-->
<!--              &lt;!&ndash; Slides &ndash;&gt;-->
<!--              <ng-template swiperSlide *ngFor="let topFood of restaurant.slideGalleryFoods.topFoods">-->
<!--                <app-preview-modal-slide-->
<!--                  [restaurant]="restaurant"-->
<!--                  [backgroundImage]="backgroundImage"-->
<!--                  [topFood]="topFood"-->
<!--                  [hoursEnabled]="true"-->
<!--                ></app-preview-modal-slide>-->
<!--              </ng-template>-->

<!--              <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.outside">-->
<!--                <app-preview-modal-slide-->
<!--                  [restaurant]="restaurant"-->
<!--                  [backgroundImage]="restaurant.slideGalleryFoods.outside.images[0].path | imgix"-->
<!--                  [hoursEnabled]="true"-->
<!--                ></app-preview-modal-slide>-->
<!--              </ng-template>-->

<!--              <ng-template swiperSlide *ngIf="restaurant.slideGalleryFoods.inside">-->
<!--                <app-preview-modal-slide-->
<!--                  [restaurant]="restaurant"-->
<!--                  [backgroundImage]="restaurant.slideGalleryFoods.inside.images[0].path | imgix"-->
<!--                  [hoursEnabled]="true"-->
<!--                ></app-preview-modal-slide>-->
<!--              </ng-template>-->

<!--              <ng-template swiperSlide *ngFor="let googleImage of restaurant.slideGalleryFoods.googleImages">-->
<!--                <app-preview-modal-slide-->
<!--                  [restaurant]="restaurant"-->
<!--                  [backgroundImage]="googleImage.images[0].path | googleImage:360"-->
<!--                  [hoursEnabled]="true"-->
<!--                ></app-preview-modal-slide>-->
<!--              </ng-template>-->
<!--            </swiper>-->

<!--            <div-->
<!--              [ngClass]="{ 'visibility-hidden': restaurant.slideGalleryFoods.empty }"-->
<!--              class="restaurant-preview-overlay-pagination w-100 d-flex justify-content-center"-->
<!--            >-->
<!--              <div class="pointer-events-auto">-->
<!--                <div-->
<!--                  class="restaurant-preview-swiper-pagination restaurant-preview-swiper-pagination-{{ restaurant.id }}"-->
<!--                ></div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <app-desktop-modal-sidenav-->
<!--              *ngIf="restaurant"-->
<!--              [restaurant]="restaurant"-->
<!--              [basket]="basket"-->
<!--              [showOwnershipDetails]="owner"-->
<!--              [basketChange]="basketClick"-->
<!--              (instagramClicked)="onInstagramLinkClick()"-->
<!--              (mmmmClicked)="onMmmmClick()"-->
<!--              (videoMenuClicked)="onVideoMenuClick()"-->
<!--              (instagramMenuClicked)="onInstagramMenuClick()"-->
<!--              (ownerClick)="owner = $event"-->
<!--              (basketChangeClick)="basketClick = $event"-->
<!--              (sidenavHover)="sidenavHovered($event)"-->
<!--              (click)="nextSlide()"-->
<!--              (previousClick)="changeRestaurantToPrevious($event)"-->
<!--              (nextClick)="changeRestaurantToNext($event)"-->
<!--              class="z-index-10"-->
<!--            ></app-desktop-modal-sidenav>-->
<!--          </div>-->
<!--        </div>-->

<!--&lt;!&ndash;        <div class="bottom-div-position bg-black">&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="d-flex justify-content-between text-white pb-3 preview-phone cursor-pointer">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="text-white font-size-0-98rem col-6 flex-content-hc mt-0-7rem" (click)="onMMMMIconClick()">&ndash;&gt;-->
<!--&lt;!&ndash;              Link video&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->

<!--&lt;!&ndash;            <app-info-bar&ndash;&gt;-->
<!--&lt;!&ndash;              *ngIf="restaurant"&ndash;&gt;-->
<!--&lt;!&ndash;              [isModal]="true"&ndash;&gt;-->
<!--&lt;!&ndash;              [isRestaurantModal]="true"&ndash;&gt;-->
<!--&lt;!&ndash;              [typeCategorySection]=typeCategorySection&ndash;&gt;-->
<!--&lt;!&ndash;              [restaurant]="restaurant"&ndash;&gt;-->
<!--&lt;!&ndash;              class="font-size-0-98rem"&ndash;&gt;-->
<!--&lt;!&ndash;            ></app-info-bar>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<!--<div-->
<!--  appModalCloseOnClickPrevent-->
<!--  *ngIf="modalArrows && hasPrev && restaurant"-->
<!--  ngbTooltip="Try keyboard arrows!"-->
<!--  class="btn btn-round arrow-bar left-n3rem d-flex align-items-center cursor-pointer justify-content-center z-index-10"-->
<!--  (click)="changeRestaurantToPrevious(restaurant)"-->
<!--  (mouseover)="arrowHover = true"-->
<!--  (mouseleave)="arrowHover = false"-->
<!--&gt;-->
<!--  <div class="icon-v2 arrow-forward-white side-arrow-right cursor-pointer"></div>-->
<!--</div>-->

<!--<div-->
<!--  appModalCloseOnClickPrevent-->
<!--  *ngIf="modalArrows && hasNext && restaurant"-->
<!--  class="btn btn-round arrow-bar right-n3rem d-flex align-items-center cursor-pointer justify-content-center z-index-10"-->
<!--  (click)="changeRestaurantToNext(restaurant)"-->
<!--  (mouseover)="arrowHover = true"-->
<!--  (mouseleave)="arrowHover = false"-->
<!--&gt;-->
<!--  <div class="icon-v2 arrow-forward-white rotate-180 side-arrow-right cursor-pointer"></div>-->
<!--</div>-->

<!--<div class="left-scroll-area z-index-0 cursor-pointer" (click)="closeModal()"></div>-->

<!--<div class="right-scroll-area z-index-0 cursor-pointer" (click)="closeModal()"></div>-->

<!--<ng-container *ngIf="!restaurantReady">-->
<!--&lt;!&ndash;  TODO need to add skeleton loader &ndash;&gt;-->
<!--</ng-container>-->


<!--        <div-->
<!--          [ngClass]="{'cursor-pointer': owner}"-->
<!--          class="restaurant-modal-left left-content-container d-flex flex-column overflow-scroll"-->
<!--          style="padding-top: 100px; position: absolute; top: 0; left: 0; bottom: 0; z-index: 3;"-->
<!--          (click)="owner = false"-->
<!--        >-->
<!--          <div-->
<!--            class="d-flex justify-content-end w-100 h-100 flex-column z-index-1 position-relative"-->
<!--            [ngClass]="{-->
<!--              'backdrop-filter-brightness-1': sidenavHover,-->
<!--              'backdrop-filter-brightness-0' : !sidenavHover-->
<!--            }"-->
<!--          >-->

<!--            &lt;!&ndash; TODO SEARCH-> add loader before restaurant loading &ndash;&gt;-->
<!--            <div-->
<!--              [ngClass]="{'d-none': sidenavHover}"-->
<!--              class="restaurant-hours-desktop-content"-->
<!--            >-->
<!--              <div *ngIf="restaurant?.periodsLength" class="position-relative text-white rest-hours-padding">-->
<!--                <app-restaurant-hours-->
<!--                  [restaurantHoursSections]="restaurant.hoursSectionsView"-->
<!--                  [isDesktop]="true"-->
<!--                  [isModal]="true"-->
<!--                ></app-restaurant-hours>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div-->
<!--              #modalContent-->
<!--              [ngClass]="{'d-none': sidenavHover}"-->
<!--            >-->
<!--              <ng-container *ngIf="needToShowVideo">-->
<!--                <div class="bottom-section d-flex flex-column mx-2rem pt-3">-->
<!--                  <div class="input-group">-->
<!--                    <input-->
<!--                      #videoInput-->
<!--                      type="url"-->
<!--                      class="form-control border-white box-shadow-none text-white"-->
<!--                      placeholder="Paste a video link featuring {{ restaurant?.nameView | abbreviate: false: '' }}"-->
<!--                      (input)="validateUrl($event)"-->
<!--                    />-->

<!--                    <span *ngIf="inputUrlValid" class="input-group-append">-->
<!--                    <button-->
<!--                      type="button"-->
<!--                      class="btn btn-primary waves-effect text-transform-none"-->
<!--                      (click)="onVideoSubmitClick(videoInput.value)"-->
<!--                    >-->
<!--                      Submit-->
<!--                    </button>-->
<!--                  </span>-->
<!--                  </div>-->

<!--                  <div class="text-right">-->
<!--                  <span class="float-left mt-2 text-white">-->
<!--                    YouTube • TikTok • Rumble • etc-->
<!--                  </span>-->

<!--                    <button-->
<!--                      type="button"-->
<!--                      class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"-->
<!--                      (click)="restoreMenu()"-->
<!--                    >-->
<!--                      Cancel-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div-->
<!--                  *ngIf="needToShowInstagram"-->
<!--                  class="bottom-section d-flex flex-column mx-2rem"-->
<!--                  [ngClass]="{'pt-3': !mmmmVideoMenu, 'pt-5-5rem': mmmmVideoMenu}"-->
<!--                >-->
<!--                  <div class="input-group">-->
<!--                    <input-->
<!--                      #instagramInput-->
<!--                      type="text"-->
<!--                      required-->
<!--                      minlength="3"-->
<!--                      maxlength="30"-->
<!--                      placeholder="Add Instagram username"-->
<!--                      class="form-control border-white box-shadow-none text-white"-->
<!--                      (input)="validateTextInput($event)"-->
<!--                    />-->

<!--                    <span *ngIf="inputTextValid" class="input-group-append">-->
<!--                    <button-->
<!--                      type="button"-->
<!--                      class="btn btn-primary waves-effect text-transform-none ig-submit-btn"-->
<!--                      (click)="onInstagramSubmitClick(instagramInput.value)"-->
<!--                    >-->
<!--                      Submit-->
<!--                    </button>-->
<!--                  </span>-->
<!--                  </div>-->

<!--                  <div class="text-right">-->
<!--                  <span class="float-left mt-2 text-white">-->
<!--                    For {{ restaurant.secondNameView }}-->
<!--                  </span>-->

<!--                    <button-->
<!--                      *ngIf="!mmmmVideoMenu"-->
<!--                      type="button"-->
<!--                      class="btn btn-default btn-link md-btn-flat waves-effect mr-n3"-->
<!--                      (click)="restoreMenu()"-->
<!--                    >-->
<!--                      Cancel-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </ng-container>-->
<!--            </div>-->

<!--            <div class="m-auto"></div>-->

<!--            &lt;!&ndash; TODO SEARCH-> add loader before restaurant loading &ndash;&gt;-->

<!--          </div>-->
<!--        </div>-->

<!--        <div-->
<!--          *ngIf="!basket.isEmpty() && !owner && basketClick"-->
<!--          class="content-container font-size-0-98rem"-->
<!--        >-->
<!--          <app-basket-modal [ngClass]="{'d-none': sidenavHover}" [basket]="basket"></app-basket-modal>-->
<!--        </div>-->
<!--        <div-->
<!--          *ngIf="owner"-->
<!--          id="owner-container"-->
<!--          class="content-container flex-column d-flex h-100 position-relative"-->
<!--        >-->
<!--          <div-->
<!--            [ngClass]="{'d-none': sidenavHover}"-->
<!--            class="content-container h-100 d-flex flex-column bg-dark"-->
<!--          >-->
<!--            <div-->
<!--              class="d-flex justify-content-end w-100 h-100 flex-column z-index-1 position-relative backdrop-filter-brightness-0"-->
<!--            >-->
<!--              <div class="position-absolute-0 font-size-1rem d-flex flex-column">-->
<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="w-100 d-flex justify-content-center"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  <div class="icon m-large-white scale-7 mt-7-4rem"></div>-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-7rem"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  The Mber community-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  M powers owners to share-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  * Complete menus with updates *-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  * Holiday hours & events *-->
<!--                </div>-->

<!--                <div-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="text-white w-100 d-flex justify-content-center mt-3"-->
<!--                  (click)="owner = false"-->
<!--                >-->
<!--                  * Allergen warnings *-->
<!--                </div>-->

<!--                <div [ngClass]="{'cursor-pointer': owner}" class="m-auto" (click)="owner = false"></div>-->

<!--                <input-->
<!--                  autofocus-->
<!--                  [ngClass]="{'cursor-pointer': owner}"-->
<!--                  class="visibility-hidden" (click)="owner = false"-->
<!--                >-->

<!--                <div class="w-100 d-flex justify-content-center pb-085-rem">-->
<!--                  <div-->
<!--                    #dropdownRef-->
<!--                    ngbDropdown-->
<!--                    appNgbDropdownOnHover-->
<!--                    placement="top-right"-->
<!--                    class="mr-1-5rem cursor-pointer"-->
<!--                  >-->
<!--                    <div-->
<!--                      ngbDropdownToggle-->
<!--                      class="text-white text-transform-none font-size-0-98rem opacity-35-to-1-on-hover hide-arrow"-->
<!--                    >-->
<!--                      Tell this owner-->
<!--                    </div>-->

<!--                    <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">-->
<!--                      <a-->
<!--                        appHrefNone-->
<!--                        class="dropdown-item flex-content-vc"-->
<!--                        (click)="shareRestaurant('twitter')"-->
<!--                      >-->
<!--                        <span class="flex-fill text-white">Tweet</span>-->

<!--                        <span class="icon-v2 twitter-white scale-08"></span>-->
<!--                      </a>-->

<!--                      <a-->
<!--                        appHrefNone-->
<!--                        class="dropdown-item flex-content-vc"-->
<!--                        (click)="shareRestaurant('facebook')"-->
<!--                      >-->
<!--                        <span class="flex-fill text-white">Post</span>-->

<!--                        <span class="icon-v2 facebook-white scale-08"></span>-->
<!--                      </a>-->

<!--                      <a appHrefNone class="dropdown-item flex-content-vc" (click)="copyLink()">-->
<!--                        <span class="flex-fill text-white">Copy Link</span>-->

<!--                        <span class="icon-v2 chain-white scale-08"></span>-->
<!--                      </a>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                  <div-->
<!--                    class="text-white text-transform-none cursor-pointer font-size-0-98rem opacity-35-to-1-on-hover"-->
<!--                    (click)="onOwnVerificationClicked()"-->
<!--                  >-->
<!--                    Get owner access-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
