<div class="modal-body h-100 w-100 d-flex p-0">
  <div
    class="content-container h-100 w-100 d-flex divider-background"
    [appBackgroundUrl]="backgroundImage"
  >
    <div class="divider-backdrop w-100 h-100 text-white px-2-5-rem d-flex flex-column">
      <div class="d-flex justify-content-between">
        <div></div>

        <div class="mt-4 font-weight-bold section-title">
          <div>{{ typeCategorySection ? typeCategorySection.title : '' }}</div>

          <div class="city-name"> {{ cityName }} </div>
        </div>
      </div>
    </div>
  </div>

<!--  <div-->
<!--    *ngIf="hasPrev"-->
<!--    ngbTooltip="Try keyboard arrows!"-->
<!--    class="btn btn-round arrow-bar left-n3rem d-flex align-items-center cursor-pointer justify-content-center"-->
<!--    (click)="previousClick.emit(true)"-->
<!--    (mouseover)="arrowHover = true"-->
<!--    (mouseleave)="arrowHover = false"-->
<!--  >-->
<!--    <div class="icon-v2 arrow-forward-white side-arrow-right cursor-pointer"></div>-->
<!--  </div>-->

<!--  <div-->
<!--    *ngIf="hasNext"-->
<!--    class="btn btn-round arrow-bar right-n3rem d-flex align-items-center cursor-pointer justify-content-center"-->
<!--    (click)="nextClick.emit(true)"-->
<!--    (mouseover)="arrowHover = true"-->
<!--    (mouseleave)="arrowHover = false"-->
<!--  >-->
<!--    <div class="icon-v2 arrow-forward-white rotate-180 side-arrow-right cursor-pointer"></div>-->
<!--  </div>-->
</div>

