<ng-container *appIfIsDesktop>
  <input
    appSearchInputDropdownWidth
    #searchRestaurantInputDesktop
    [ngClass]="inputClass"
    [ngbTypeahead]="inputHover ? searchRest : null"
    [placement]="'bottom-left'"
    [popupClass]="'search-location-popup search-location-popup-desktop search-location-popup-desktop-restaurant'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    [resultTemplate]="rt"
    type="text"
    class="form-control form-control-input input-placeholder input-placeholder-hidden"
    id="restaurant-input"
    placeholder="        Restaurant name"
    (selectItem)="selectItem($event)"
    (focus)="onFocusInput()"
    (mouseenter)="onFocusInput()"
    (input)="onInputClick($event)"
  >

  <ng-template #rt let-r="result" let-t="term">
    <div
      *ngIf="!r.disabled"
      class="d-flex align-items-center"
    >
      <ngb-highlight [result]="r.display" [term]="t"></ngb-highlight>
    </div>
  </ng-template>
</ng-container>

<div *appIfIsMobile class="mobile-icons">
  <i *ngIf="!hiddenRestPlaceholder" class="icon-v2 search-black-new" (click)="iconClicked()"></i>

  <input
    #searchRestaurantInput
    [ngbTypeahead]="searchRest"
    [ngClass]="inputClass"
    [placement]="'bottom-left'"
    [popupClass]="'search-city-popup search-city-popup-mobile'"
    [focusFirst]="false"
    [editable]="false"
    [inputFormatter]="formatter"
    [resultFormatter]="formatter"
    autocomplete="new-search-city"
    class="form-control"
    type="text"
    placeholder="           Restaurant name"
    (input)="restInput($event)"
    (selectItem)="selectItem($event)"
  >
</div>
