<div *ngIf="!areModalsOpen" class="search-desktop d-flex flex-column h-100">
  <div class="search-desktop-background search-desktop-background-active"></div>

  <div class="d-flex search-desktop-content d-flex flex-column h-100" (scroll)="onScroll()">
    <div
      *ngIf="!isLocalitySelected && !isSearching"
      class="z-index-3 d-flex flex-column w-100 h-100vh"
    >
      <div
        *ngIf="dataLoaded && stateSelected && !countySelected && stateSelected.flagUrl"
        class="d-flex flex-column align-items-center justify-content-center h-100vh"
      >
        <img
          class="w-30"
          [src]="stateSelected?.flagUrl"
          [alt]="stateSelected?.display"
        >
      </div>
    </div>

    <app-search-desktop-loader *ngIf="isSearching" class="w-100"></app-search-desktop-loader>

    <ng-container *ngIf="!isSearching">
      <app-search-desktop-topnav
        [searchParams]="searchParams"
        [searchResult]="searchResult"
        [isSearching]="isSearching"
        [country]="country"
        [preview]="preview"
        [locationFavorites]="locationFavorites"
        (videoModalClicked)="openVideoModal()"
        (citySelected)="onCitySelected($event)"
        (previewChange)="onPreviewChange()"
        (locationDropdownChange)="onLocationDropdownChange()"
        (localitySelectedChange)="onLocalitySelectedChange($event)"
        (countyChange)="onCountyChange($event)"
        (stateChange)="onStateChange($event)"
        class="d-flex align-items-start"
      ></app-search-desktop-topnav>

      <div [ngClass]="{'pb-0 pl-0 pt-58px': preview || divider}" class="search-desktop-main flex-fill overflow-auto">
        <ng-container *ngIf="searchResult.groupsFiltered?.length > 0 && isLocalitySelected && !(preview || divider)">
          <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionFirst = first;">
            <ng-container  *ngFor="let typeCategorySection of group.sections; let typeCategorySectionFirst = first;">
              <div
                #exploreEmpty
                *ngIf="typeCategorySection.hasChain || typeCategorySection.hasRestaurants"
                [attr.data-keys]="typeCategorySection.keysString"
                [ngClass]="{
                  'search-desktop-category-section-first': sectionFirst && typeCategorySectionFirst,
                  'search-desktop-category-section-example': typeCategorySection.isExample
                }"
                id="search-desktop-category-section-{{typeCategorySection.keysString}}"
                class="search-desktop-category-section"
              >
                <div
                  class="category-opacity flex-content-vc-hr text-uppercase text-white font-weight-600 cursor-pointer"
                  (click)="openDividerModal(typeCategorySection)"
                >
                  {{ typeCategorySection.title }}
                </div>

                <div
                  *ngFor="let typeCategory of typeCategorySection.typeCategories;"
                  class="search-desktop-category"
                >
                  <ng-container *ngFor="let restaurant of typeCategory.restaurants;">
                    <div
                      class="phantom-block-desktop phantom-block-desktop-explore"
                      id="restaurant-{{restaurant.id}}"
                    ></div>

                    <div class="search-desktop-restaurant">
                      <div class="flex-content-vc-hr">
                        <div class="search-desktop-restaurant-header d-flex justify-content-end">
                          <div class="flex-fill">
                            <a
                              appHrefNone
                              class="search-desktop-restaurant-name"
                              (click)="openRestaurantModal(restaurant, typeCategorySection)"
                              (mouseenter)="restaurant.hover = true"
                              (mouseleave)="restaurant.hover = false"
                            >
                              {{ restaurant.display | abbreviate:true:'' }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <div
                  *ngIf="!typeCategorySection.isExample"
                  class="category-position search-desktop-restaurant search-desktop-restaurant-header search-desktop-category"
                >
                  <a
                    [ngClass]="{
                      'opacity-35': !typeCategorySection.hasChain,
                      'cursor-pointer': typeCategorySection.hasChain
                    }"
                    (click)="openChainDesktopModal(typeCategorySection)"
                    class="icon-v2 chains-new"
                  ></a>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div
            [ngClass]="{'opacity-0': !isLocalitySelected}"
            class="d-flex justify-content-end align-items-end mt-2rem"
          >
            <span
              class="cursor-pointer font-size-1-85rem text-white opacity-35-to-1-on-hover"
              (click)="addRestaurantDesktop()"
            >
              Add restaurant
            </span>
          </div>
        </ng-container>

        <app-restaurant-modal
          *ngIf="preview"
          [typeCategorySection]="activeTypeCategorySection"
          [restaurantExplore]="clickedRestaurant"
          [modalArrows]="true"
          [hasPrev]="hasPrev"
          [hasNext]="hasNext"
          (previousClick)="previousClick()"
          (nextClick)="nextClick()"
          class="h-100"
        ></app-restaurant-modal>

        <app-divider-modal
          *ngIf="divider"
          [typeCategorySection]="activeTypeCategorySection"
          [cityName]="searchParams.locationName"
          [hasPrev]="hasPrev"
          [hasNext]="hasNext"
          (previousClick)="previousClick()"
          (nextClick)="nextClick()"
          class="h-100"
        ></app-divider-modal>
      </div>

      <div class="search-desktop-sidebar overflow-auto bottom-0 text-white d-flex flex-column">
        <div class="search-desktop-controls d-flex flex-column ">
          <div class="search-desktop-controls-content d-flex mt-4-5rem">
            <div class="d-flex flex-column search-desktop-restaurants">
              <div *ngIf="preview || divider" class="d-flex m-0">
                <div class="d-flex flex-column" style="width: calc(100% - 222px); height: 100%; padding-left: 1rem; overflow-y: auto; flex-shrink: 0;">
                  <ng-container *ngIf="searchResult.groupsFiltered?.length > 0 && isLocalitySelected">
                    <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionFirst = first;">
                      <ng-container  *ngFor="let typeCategorySection of group.sections; let typeCategorySectionFirst = first;">
                        <div
                          *ngIf="typeCategorySection.hasChain || typeCategorySection.hasRestaurants"
                          [attr.data-keys]="typeCategorySection.keysString"
                          [ngClass]="{
                            'pt-2': sectionFirst && typeCategorySectionFirst,
                            'search-desktop-category-section-example': typeCategorySection.isExample
                          }"
                          id="search-desktop-category-section-{{typeCategorySection.keysString}}"
                          class="search-desktop-category-section"
                        >
                          <div
                            *ngFor="let typeCategory of typeCategorySection.typeCategories;"
                            class="search-desktop-category"
                          >
                            <ng-container *ngFor="let restaurant of typeCategory.restaurants;">
                              <div
                                class="phantom-block-desktop phantom-block-desktop-explore"
                                id="restaurant-{{restaurant.id}}"
                              ></div>

                              <div class="search-desktop-restaurant mt-0 pt-3">
                                <div class="search-desktop-restaurant-header d-flex justify-content-end">
                                  <div class="flex-fill">
                                    <a
                                      appHrefNone
                                      [ngClass]="{
                                        'opacity-65': !restaurant.hover && !isActiveRestaurant(restaurant),
                                        'opacity-1': restaurant.hover || isActiveRestaurant(restaurant)
                                      }"
                                      class="text-white font-size-1rem font-weight-400"
                                      (click)="openRestaurantModal(restaurant, typeCategorySection)"
                                      (mouseenter)="restaurant.hover = true"
                                      (mouseleave)="restaurant.hover = false"
                                    >
                                      {{ restaurant.display | abbreviate:true:'' }}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

                <div class="p-0 d-flex" style="width: 222px; height: calc(100% - 4.5rem); overflow: hidden; flex-shrink: 0; position: fixed; right: 0;">
                  <div class="pt-3 d-flex flex-column">
                    <ng-container *ngIf="searchResult.groupsFiltered && isCitySelected">
                      <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionI = index;">
                        <div
                          class="search-desktop-section search-desktop-section-margin"
                          [ngClass]="{'d-flex flex-column search-desktop-section-margin': sectionI != 0}"
                        >
                          <ng-container *ngFor="let typeCategorySection of group.sections; let i = index;">
                            <div *ngIf="!typeCategorySection.isExample" class="search-desktop-section-title">
                              <button
                                [ngClass]="{
                                  'opacity-065-to-1-on-hover': !(sectionActive && sectionActive == typeCategorySection),
                                  'btn-active opacity-1': sectionActive && sectionActive == typeCategorySection,
                                  'opacity-0': !isLocalitySelected
                                }"
                                [disabled]="!typeCategorySection.hasRestaurants || !isLocalitySelected"
                                class="btn btn-dark btn-primary-shadow md-btn-flat text-transform-none font-weight-400 pl-0"
                                (click)="restaurantSelect(typeCategorySection)"
                              >
                                {{ typeCategorySection.title }}
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="searchResult.groupsFiltered && isCitySelected && !(preview || divider)">
                <ng-container *ngFor="let group of searchResult.groupsFiltered; let sectionI = index;">
                  <div
                    class="search-desktop-section search-desktop-section-margin d-flex flex-column "
                    [ngClass]="{'d-flex flex-column search-desktop-section-margin': sectionI != 0}"
                  >
                    <ng-container *ngFor="let typeCategorySection of group.sections; let i = index;">
                      <div *ngIf="!typeCategorySection.isExample" class="search-desktop-section-title">
                        <button
                          [ngClass]="{
                            'btn-active opacity-1': sectionActive && sectionActive == typeCategorySection,
                            'opacity-0': !isLocalitySelected
                          }"
                          [disabled]="!typeCategorySection.hasRestaurants || !isLocalitySelected"
                          class="btn btn-dark btn-primary-shadow md-btn-flat text-transform-none font-weight-400"
                          (click)="typeCategorySectionSelect(typeCategorySection)"
                        >
                          {{ typeCategorySection.title }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <div
              class="d-flex flex-column"
              [ngClass]="{
                'search-desktop-filters': isLocalitySelected
              }"
            >
              <div
                *ngIf="false"
                [ngClass]="{'visibility-hidden': isCitySelected}"
                class="search-desktop-add-share"
              >
                <a
                  appHrefNone
                  appOpacityFullOnHover
                  class="d-inline-block text-white opacity-50 mb-2"
                  (click)="addRestaurantDesktop()"
                >
                  Add
                </a>

                <br>

                <div ngbDropdown>
                  <a
                    appHrefNone
                    ngbDropdownToggle
                    appOpacityFullOnHover
                    class="d-inline-block text-white opacity-50 hide-arrow"
                  >
                    Share
                  </a>

                  <div ngbDropdownMenu>
                    <a
                      href="https://instagram.com/mmmm.menu"
                      target="_blank"
                      class="dropdown-item"
                    >
                      Instagram
                    </a>

                    <a appHrefNone class="dropdown-item" (click)="shareMMMM('twitter')">
                      Twitter
                    </a>

                    <a appHrefNone class="dropdown-item" (click)="shareMMMM('facebook')">
                      Facebook
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #restaurantAndChainsAndDividerModalRef>
  <app-restaurant-modal
    *ngIf="activeSearchResultSlide?.type === searchResultSlideType.RESTAURANT"
    [typeCategorySection]="activeTypeCategorySection"
    [restaurantExplore]="clickedRestaurant"
    [modalArrows]="true"
    [hasPrev]="hasPrev"
    [hasNext]="hasNext"
    (previousClick)="previousClick()"
    (nextClick)="nextClick()"
    class="h-100"
  ></app-restaurant-modal>

  <app-chain-modal-desktop
    *ngIf="activeSearchResultSlide?.type === searchResultSlideType.CHAIN"
    [typeCategorySection]="activeTypeCategorySection"
    [cityName]="searchParams.locationName"
    [hasPrev]="hasPrev"
    [hasNext]="hasNext"
    (previousClick)="previousClick()"
    (nextClick)="nextClick()"
    class="h-100"
  ></app-chain-modal-desktop>

  <app-divider-modal
    *ngIf="activeSearchResultSlide?.type === searchResultSlideType.DIVIDER"
    [typeCategorySection]="activeTypeCategorySection"
    [cityName]="searchParams.locationName"
    [hasPrev]="hasPrev"
    [hasNext]="hasNext"
    (previousClick)="previousClick()"
    (nextClick)="nextClick()"
    class="h-100"
  ></app-divider-modal>
</ng-template>

<ng-template #favoriteDesktopModalRef>
  <app-favorites-list
    [cityName]="searchParams.locationName"
    [favoriteList]="true"
    [locationFavorites]="locationFavorites"
    [searchResult]="searchResult"
    (favoriteStatusChanged)="onFavoriteStatusChange()"
    (desktopRestaurantNameClick)="openRestaurantModal($event, activeTypeCategorySection)"
  ></app-favorites-list>
</ng-template>

<ng-template #addRestaurantModalRef>
  <app-restaurant-add-modal></app-restaurant-add-modal>
</ng-template>

<ng-template #videoModalRef>
  <app-video-desktop-modal></app-video-desktop-modal>
</ng-template>

<ng-template #videoDesktopModalRef let-c="close">
  <app-video-modal
    [searchParams]="searchParams"
  ></app-video-modal>
</ng-template>
